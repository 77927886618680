import React from "react"
import { graphql, PageProps as GatsbyPageProps } from "gatsby"
import Layout from "../../components/layout"
import Header from "../../components/header"
import SectionLoader from "../../sections/section-loader"
import Footer from "../../components/footer"
import { MainContent } from "../../ui"
import {
  PageQuery,
  IBanner,
  IFooter,
  ILocale,
  INavigationBar,
  Scalars,
  IMediaAsset,
} from "../../../gatsby-graphql"
import { LayoutStyles } from "./page-styles"
import { pageSettings } from "./page-settings"
import { ILocaleLink } from "../../components/top-bar/common/Locales"

interface PageContext {
  locale: string
  locales: ILocaleLink[]
}

interface PageProps extends GatsbyPageProps {
  data: PageQuery
  pageContext: PageContext
}

const Page = ({ data, pageContext }: PageProps) => {
  const settings = pageSettings[data.page?.slug || "default"] || {}
  return (
    <Layout
      seo={data.page?.seo || undefined}
      topBar={data.topBar}
      locales={pageContext.locales}
      className={`page-${data.page?.slug}`} // TODO: change to configurable template
      additionalStyles={LayoutStyles}
      locale={pageContext.locale}
    >
      <Header
        data={data.page?.header as IBanner}
        navBarData={data.navBar as INavigationBar}
        className={settings.headerClass}
        locales={pageContext.locales}
        rwdLogo={data.global?.logo as IMediaAsset}
        locale={pageContext.locale}
      />
      <MainContent>
        {data.page?.sections.length > 0 &&
          data.page?.sections.map((section: Scalars["JSON"], index: number) => (
            <SectionLoader data={section} locale={pageContext.locale} key={index} />
          ))}
      </MainContent>
      <Footer data={data.footer as IFooter}
              locales={pageContext.locales}
              locale={pageContext.locale}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query Page($id: String!, $locale: String!) {
    global: strapiGlobal {
      ...FGlobal
    }
    topBar: strapiTopBar(locale: { eq: $locale }) {
      ...FTopBar
    }
    navBar: strapiNavigationBar(locale: { eq: $locale }) {
      ...FNavigationBar
    }
    footer: strapiFooter(locale: { eq: $locale }) {
      ...FFooter
    }
    page: strapiPage(id: { eq: $id }) {
      ...FPage
    }
  }
  fragment FPage on StrapiPage {
    id
    slug
    title
    seo {
      ...FSeo
    }
    header {
      ...FBanner
    }
    sections
  }
`
