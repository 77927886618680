import { css } from "styled-components"
import { fonts } from "../../../helpers/fonts"
import {
  Background as FullWidthBannersBackground,
  ContentWrapper as FullWidthBannersContentWrapper,
} from "@gamigo/gatsby-theme-strapi/src/sections/full-width-banners/full-width-banners-elements"

export const LayoutStyles = css`
  // first full width banner
  section:first-child {
    ${FullWidthBannersBackground} {
      border: 0;
    }
    ${FullWidthBannersContentWrapper} {
      max-width: 850px;
      text-align: center;
      h2 {
        text-transform: uppercase;
        font-size: ${fonts.sizes[8]};
        line-height: 0.5em;
      }
      p {
        font-size: ${fonts.sizes[2]};
        line-height: 27px;
      }
    }
  }

  // last full width banner
  section:last-child {
    ${FullWidthBannersContentWrapper} > div {
      display: flex;
      justify-content: center;
      margin-top: 33%;
    }
  }
`
