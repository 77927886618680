import { gameSettings } from "./game/game-settings"

export interface IPageSettings {
  headerClass?: string
}

interface IPagesSettings {
  [template: string]: IPageSettings
}

export const pageSettings: IPagesSettings = {
  game: gameSettings,
}
